import React, { useEffect, useRef, useState } from "react"
import Flickity from "react-flickity-component"
import "flickity/dist/flickity.min.css"
import { Link, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import MarkDown from "react-markdown"

//component
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LiveChat from "../../components/Home/livechat";
import TestimonialsText from "../../components/Home/testimonials-text"

// img
import Cond1 from "../../../images/uploads/contemporary/cond1.png"
import Conlight from "../../../images/uploads/contemporary/conlight.png"
import Condark from "../../../images/uploads/contemporary/condark.png"
import Conlight1 from "../../../images/uploads/contemporary/conlight1.png"
import Condark1 from "../../../images/uploads/contemporary/condark1.png"
import Cond2 from "../../../images/uploads/contemporary/cond2.png"
import Cond4 from "../../../images/uploads/contemporary/cond4.png"
import Cond5 from "../../../images/uploads/contemporary/cond5.png"
import Cond6 from "../../../images/uploads/contemporary/cond6.png"
import Cond7 from "../../../images/uploads/contemporary/cond7.png"
import Cond6light from "../../../images/uploads/contemporary/wacom6light.png"
import Cond6dark from "../../../images/uploads/contemporary/wacom6dark.png"
import Cons1 from "../../../images/uploads/contemporary/cons1.png"
import Cons2 from "../../../images/uploads/contemporary/cons2.png"
import Cons3 from "../../../images/uploads/contemporary/cons3.png"

import addcs1 from "../../../images/uploads/decodeup-smart-additional-case-study.png"
import addcs2 from "../../../images/uploads/decodeup-cwatch-additional-case-study.png"
const Contemporary = props => {
  const SEOdata = props.data.pageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.pageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.pageData.edges[0].node.frontmatter?.Hreflang
  const [margin, setMargin] = useState()
  const flRef = useRef()

  useEffect(() => {
    let box = document.querySelector(".container")
    let width = box.offsetWidth
    let innerw = window.innerWidth
    let paddingApplied = innerw - width

    setMargin(paddingApplied)
  })

  const options = {
    // contain: true,
    // wrapAround: true,
    // initialIndex: 2,
    // pageDots: false,
    // autoPlay: false,
    // prevNextButtons: false,
    // adaptiveHeight: true,
    lazyLoad: true,
    prevNextButtons: true,
    cellAlign: "left",
    contain: true,
    freeScroll: true,
    pageDots: false,
    percentPosition: true,
    adaptiveHeight: true,
  }

  const onMouseEnter = () => {
    flRef.current.flkty.player.play()
  }
  const onMouseLeave = () => {
    flRef.current.flkty.player.stop()
  }
  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "contemporarydentistry"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />

        {/* banner  section */}
        <section className="h-full  xl:h-screen case-draft-section">
          <div className="h-full container mx-auto  ">
            <div className="pb-20 pt-1-63 md:py-2-00 lg:pt-64 h-full flex flex-col justify-center">
              <div className="mb-6-6 lg:mb-50 w-full  case-study-project-heading ">
                <h4 className="pr-5 lg:pl-0 mb-1-2 lg:mb-3-2 font-poppings-normal text-p3 lg:text-p2 text-term-primary">
                  Modern Dentist in Dearborn, MI
                </h4>
                <h1 className="mb-3 lg:mb-5 font-poppings-bold text-h2 lg:text-h0 xl:text-h1 text-term-primary">
                  Dental Health Care Website
                </h1>
                <p className="w-full lg:w-11/20  font-worksans-normal text-p4  md:text-h5 lg:text-p2 text-term-primary">
                  The goal of this site is to engage with new information and to
                  create new, interesting entities in the process. We began by
                  talking with the client about the overall purpose of the
                  website, which was both for education and for promoting the
                  Snotes company in a fun, original way.
                </p>
              </div>

              <div className="project-details-draft">
                <div className="flex flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      CLIENT
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Will H.
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      COUNTRY
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      USA
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      SERVICES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Development, SEO, Maintenance and Support, UI/UX Design,
                      Product management, Hosting and Maintenance
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      TECHNOLOGIES USED
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Wordpress, JS
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      INDUSTRIES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Medical & Healthcare
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Banner section */}
        <section className="relative mb-60 md:mb-20 xl:mb-1-40">
          <img
            className="w-full h-full object-cover mx-auto"
            src={Cond1}
            alt="Contemporary Decodeup Technologies"
          />
          <div className="absolute bottom-0 left-1/2 -translate-x-1/2 con-img-sec">
            <img
              className="mx-auto con-light con-img"
              src={Conlight}
              alt="Contemporary Decodeup Technologies"
            />
            <img
              className="mx-auto con-dark con-img"
              src={Condark}
              alt="Contemporary Decodeup Technologies"
            />
          </div>
        </section>

        {/* Banner intro section */}
        <section className="relative mb-60 md:mb-20 xl:mb-1-40 pt-10 md:pt-20 xl:pt-1-40">
          <div className="container container-con mx-auto">
            <div className="flex flex-wrap sm:flex-nowrap gap-8 md:gap-8 lg:gap-16 ">
              <div className="md:pt-10 w-full md:w-1/2">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  The best dental care platform from expert providers
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  When this client approached us, we were eager to see how our
                  services could benefit their team.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  They were direct about their goals: a website that would wow
                  visitors and keep them on the site. A checkout and booking
                  service that was seamless and effective. Fully responsive
                  features that worked on any device anywhere.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The word on their mind was reliability and that's what we
                  focused on as we planned this new website together. Whether
                  the patients had a dental emergency or simply wanted to check
                  in on their smile, this site would be the place to do it all.
                </p>
              </div>
              <div className="w-full md:w-1/2">
                <img
                  className="mx-auto con-light"
                  src={Conlight1}
                  alt="Contemporary Decodeup Technologies"
                />
                <img
                  className="mx-auto con-dark"
                  src={Condark1}
                  alt="Contemporary Decodeup Technologies"
                />
              </div>
            </div>
          </div>
        </section>

        {/* intro bold */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-con mx-auto">
            <div className="mt-20 mx-auto w-full max-w-9-76 text-center">
              <h3 class="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary ">
                Working with this dental group was exciting. The project
                involved a number of features, namely booking features. Although
                it sounds straightforward, the site required many nuances that
                our designers had to plan for.
              </h3>
            </div>
          </div>
        </section>

        {/* challenges section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-con mx-auto">
            <div className="flex flex-wrap sm:flex-nowrap gap-8 lg:gap-16 xl:items-center">
              <div className="w-full lg:w-2/5 hidden md:block max-w-480 ">
                <img
                  className="mx-auto w-full h-full object-contain object-top"
                  src={Cond2}
                  alt="Contemporary Decodeup Technologies"
                />
              </div>
              <div className="w-full lg:w-3/5">
                <h2 className="mb-3 lg:mb-6-6 pr-10 font-poppings-bold text-rh2 lg:text-h2 xl:text-h1 text-term-primary">
                  The Challenges
                </h2>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We knew this platform would be used for dental care, so it was
                  important to recreate the simplicity and clarity of a dental
                  office. That meant using clean lines and minimalist design
                  elements. We wanted it to be clear that this is a place to
                  live a happy life with top-notch dental care
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We also had to address the issue of booking by creating a way
                  to book appointments without disrupting the design. On the
                  backend, this meant having options for appointments as well as
                  consultations and the ability to delete and reschedule events.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Ultimately, the hardest challenge was one that exists on every
                  project: representing the client in the best light and meeting
                  their needs at the same time. This site also needed a pricing
                  plan where patients can clearly see the services provided.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  In addition to the traditional booking features, we wanted to
                  make sure the system could notify clients and admins of new
                  appointments.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Banner section */}
        <section className="py-16 px-8 mb-60 md:mb-20 xl:mb-1-40 cond-light-yellow">
          <div className="container container-con mx-auto">
            <img
              className="w-full h-full object-cover mx-auto"
              src={Cond4}
              alt="Contemporary Decodeup Technologies"
            />
          </div>
        </section>

        {/* solutions */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-con mx-auto">
            <div className="w-full mb-6 lg:mb-16">
              <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary text-center">
                Solutions
              </h2>
            </div>
            <div className="mb-10 md:mb-20 flex flex-wrap sm:flex-nowrap gap-8 md:gap-8 lg:gap-16 items-center">
              <div className="w-full sm:w-1/2 max-w-6-08 mx-auto">
                <img
                  className="mx-auto w-full h-full object-cover "
                  src={Cond5}
                  alt="Contemporary Decodeup Technologies"
                />
              </div>
              <div className="w-full sm:w-1/2">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Easy to use backend management
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We crafted a website that could do everything our clients
                  asked for and more.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We took the responsibility of taking their vision and made it
                  into a fully dynamic site that connected the dental care
                  providers to their patients in a unique way.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The backend system was not only reliable but sleek and easy to
                  use no matter who was accessing it.
                </p>
              </div>
            </div>

            <div className="mb-10 md:mb-20 flex flex-wrap sm:flex-nowrap gap-8 md:gap-8 lg:gap-16 items-center">
              <div className="sm:order-2 w-full sm:w-1/2 max-w-6-08 mx-auto">
                <img
                  className="mx-auto w-full h-full object-cover "
                  src={Cond6}
                  alt="Contemporary Decodeup Technologies"
                />
              </div>
              <div className="sm:order-1 w-full sm:w-1/2">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  A modern take on dental care
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  For front end users we produced a highly customized site that
                  features some of the latest tech. Patients can now book their
                  own appointments with the convenient calendar tool and inquire
                  about services from their computer or mobile device. All of
                  which can be managed from the backend.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Patients also received notifications and reminders through
                  their email so they'll never miss an appointment again
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Did we mention an integrated Facebook chat for live
                  assistance?
                </p>
              </div>
            </div>

            <div className="mb-10 md:mb-20 flex flex-wrap sm:flex-nowrap gap-8 md:gap-8 lg:gap-16 items-center">
              <div className="w-full sm:w-1/2 max-w-6-08 mx-auto">
                <img
                  className="mx-auto w-full h-full object-cover "
                  src={Cond7}
                  alt="Contemporary Decodeup Technologies"
                />
              </div>
              <div className="w-full sm:w-1/2">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  A website that's one size fits all
                </h3>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  This client is now able to receive booking appointments
                  virtually and clients can connect with their dentists at the
                  tap of a finger. They can also find new providers for their
                  healthcare needs. Sometimes it's hard to please everyone, but
                  our team created a website that met the needs of everyone.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Type Face */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-con mx-auto">
            <div className="mb-10 w-full ">
              <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                Typeface & Colors
              </h2>
            </div>
            <div className="mb-8 lg:mb-12 flex flex-wrap md:flex-nowrap gap-4 md:gap-8 lg:gap-16 items-center justify-between">
              <div className="w-full md:w-1/4 max-w-480">
                <img className="con-light" src={Cond6light} />
                <img className="con-dark" src={Cond6dark} />
              </div>
              <div className="w-full mms:w-5/20 md:w-2/4 cond-typo text-term-primary">
                <h4 className="mb-3 lg:mb-8 text-term-primary">Robot</h4>
                <h5 className="mb-3 lg:mb-3 text-term-primary opacity-70">
                  ABCDEFGHIJKLMNOPQRSTUVWXYZ
                </h5>
                <h6 className="text-term-primary opacity-70">
                  abcdefghijklmnopqrstuvwxyz
                </h6>
              </div>
              <div className="mt-8 mms:mt-0 w-full mms:w-5/48 md:w-2/4 flex mms:justify-end items-center text-term-primary">
                <div className="flex justify-center items-center rounded-full w-2-72 h-2-72 cond-color-1">
                  <p className="text-rp1 lg:text-h3 font-poppings-bold text-white text-center">
                    #BF9639
                  </p>
                </div>
                <div className="mms:-ml-4 lg:-ml-8 xl:-ml-16 relative z-1 flex justify-center items-center rounded-full w-2-72 h-2-72 cond-color-2">
                  <p className="text-rp1 lg:text-h3 font-poppings-bold text-white text-center">
                    #111111
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Result */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-con mx-auto">
            <div className="md:w-7/10">
              <h2 className="mb-4 lg:mb-6-6 pr-10 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                The Result
              </h2>
              <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                We successfully created a website that all users can benefit
                from and the client was so happy with the result.
              </p>
              <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                That's the DecodeUp difference. Only the best engineers,
                designers and management working on your site at a great price.
              </p>
              <div className="mt-6 w-full">
                <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Are you ready to flash a winning smile? Whether you're
                  starting brand new or overhauling an old site, DecodeUp can
                  design it for you.
                </h3>
              </div>
            </div>
          </div>
        </section>
        {/* slider section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="case-study-slider-bg">
            <div className="container container-smt mx-auto">
              <div
                id="myid"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <Flickity
                  className={"case-studies-carousel cstm-du-casestudy-slider"}
                  elementType={"div"}
                  options={options}
                  ref={flRef}
                >
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Cons1}
                    alt="Contemporary Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Cons2}
                    alt="Contemporary Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Cons3}
                    alt="Contemporary Decodeup Technologies"
                  />
                  {/* {info.screenshots_for_carousel.map(
                        ({ image: img }, ss) => (
                          <img
                            key={ss}
                            src={getSrc(img)}
                            className="cs-carousel-image"
                            alt="decodeup case study"
                          />
                        )
                      )} */}
                </Flickity>
              </div>
            </div>
          </div>
        </section>

        {/* client testimonials carousel section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-fra mx-auto">
            <div className="pr-2 pl-5-3 lg:pl-60 lg:pr-60 xl:px-90 xl:pl-1-41 xl:pr-1-41 2xl:pl-1-86 2xl:pr-1-87">
              <TestimonialsText />
            </div>
          </div>
        </section>

        {/* additional case studies section */}
        <section className="pb-50 md:pt-50 lg:pt-62 xl:pt-0 lg:pb-1-20">
          <div className="container mx-auto">
            <div className="pb-35 lg:pb-36 text-center">
              <h3 className="d-head-title text-rp1  lg:text-h3 font-poppings-bold text-term-primary">
                Additional Case Studies
              </h3>
            </div>
            <div className="h-full flex flex-wrap">
              <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-cyan">
                <Link
                  to="/case-study/smart-notes-and-puzzles-platform"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Smart Notes & Puzzles Platform
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=" w-full h-full object-cover"
                      src={addcs1}
                      alt=" Smart Notes Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
              <div className=" pt-36 w-full sm:w-1/2 text-center bg-background-case">
                <Link
                  // to={post.fields.slug}
                  to="/case-study/truck-drivers-security-app"
                  className="h-full flex items-center justify-between flex-col text-center"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Truck Driver's Security App
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=" w-full h-full object-cover"
                      src={addcs2}
                      alt="C-watch Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <LiveChat />
      </Layout>
    </>
  )
}

export default Contemporary

export const pageQuery = graphql`
query contemporarydentistrypagequery {
  pageData: allMarkdownRemark(
    filter: { fileAbsolutePath: { regex: "/case-studies/dental-health-care-website.md/" } }
  ) {
    edges {
      node {
        frontmatter {
          seo_metadata {
            seo_title
            seo_keyword
            seo_description
            image_format
            feature_image_height
            img_width
            facebook_url
            twitter_page_username
            twitter_image_alt_tag
            twitter_estimated_reading_lable
            twitter_estimated_reading_time
            page_publish_date
            page_last_modified_time
            seo_Image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200)
              }
            }
            seo_Single_Image
          }
          Schema {
            sitelinks_schema
            breadcrumb_schema
            logo_schema
            organization_schema
            aggregate_rating_schema
            local_business_schema
            site_navigation_schema
            speakable_specification_schema
          }
          Hreflang {
            hreflang_1
            hreflang_2
            hreflang_3
            hreflang_4
          }
        }
      }
    }
  }     
}

`;